<template>
  <div class="related-products">
    <div class="related-title">
      Produse similare
    </div>
    <div id="relatedProductsCarousel" class="relatedProductsCarousel">
      <swiper :breakpoints='breakpoints' :scrollbar='{ draggable: true }' :navigation='navigation' :rewind='true'>
        <swiper-slide v-for="product in relatedProducts" :key="product.id">
          <product-card :product="product" />
        </swiper-slide>
      </swiper>
      <div class="swiper-nav">
        <div class="swiper-button-next-custom"></div>
        <div class="swiper-button-prev-custom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";

import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
  components: {
    ProductCard,
    Swiper, 
    SwiperSlide
  },
  name: "RelatedProducts",
  data() {
    return {
      breakpoints: {
        100: {
          slidesPerView: 2.2,
          spaceBetween: 8,
          slidesPerGroup: 2,
        },
        576: {
          slidesPerView: 3.5,
          spaceBetween: 10,
          slidesPerGroup: 3,
        },
        768: {
          slidesPerView: 4.5,
          spaceBetween: 15,
          slidesPerGroup: 4,
        },
      },
      navigation: {
        prevEl: '#relatedProductsCarousel .swiper-button-prev-custom',
        nextEl: '#relatedProductsCarousel .swiper-button-next-custom',
      }
    };
  },
  props: {
    products: {
      type: Array,
    },
  },
  computed: {
    relatedProducts() {
      // Create a deep copy of the products array
      const relatedArray = JSON.parse(JSON.stringify(this.products));

      // Iterate over each product and delete the properties we don't want to see on the card
      relatedArray.forEach((product) => {
        delete product.preview_text;
        delete product.labels;
        delete product.property;
      });

      relatedArray.sort((a, b) => {
        // Sort by quantity first
        if (a.offers[0].quantity > 0 && b.offers[0].quantity === 0) {
          return -1; // a comes before b
        } else if (a.offers[0].quantity === 0 && b.offers[0].quantity > 0) {
          return 1; // b comes before a
        }
        
        // THen sort by price_value
        return a.offers[0].price_value - b.offers[0].price_value;
      });

      return relatedArray;
    },
  },
};
</script>

<style>
.related-products {
  margin-bottom: 30px;
}

.related-products .related-title {
  font-family: 'Raleway Bold';
  font-size: 24px;
  color: #000;
  text-align: left;
  margin-bottom: 15px;
}

#relatedProductsCarousel .swiper-scrollbar {
  left: 0;
  width: 100%;
}

#relatedProductsCarousel .swiper-scrollbar-drag {
  background-color: #000;
}

#relatedProductsCarousel .swiper-button-next-custom, #relatedProductsCarousel .swiper-button-prev-custom {
  position: absolute; 
  background-size: 45px;
  background-position: center;
  width: 45px;
  height: 45px;
  top: 33%;
  z-index: 10;
}

#relatedProductsCarousel .swiper-button-next-custom {
  background-image: url("~@/assets/images/arrow-carousel-right.svg");
  right: -7px;
}

#relatedProductsCarousel .swiper-button-prev-custom {
  background-image: url("~@/assets/images/arrow-carousel-left.svg");  
  left: -7px;
}

.swiper-button-next-custom.swiper-button-disabled, .swiper-button-prev-custom.swiper-button-disabled {
  display: none;
}

.related-products .product-entity {
  width: 100%;
}

.related-products .product-entity .produse-name {
  font-size: 14px;
  line-height: 1;
  height: 50px;
}

.related-products .product-entity .produse-stock span {
  font-size: 11px;
}

.related-products .product-entity .produse-price {
  font-size: 16px;
  padding-top: 10px;
}


.related-products .product-entity .produs-image {
  border-radius: 9px;
  border: solid 1.5px #e5e5e5;
}

.related-products .product-entity .lichidare-de-stoc  {
  right: 20px;
}

@media (max-width: 768px) {
  #relatedProductsCarousel .swiper-nav {
    display: none;
  }

  .related-products .related-title {
    font-size: 22px;
  }

  #relatedProductsCarousel div.produse-name {
    font-size: 12px;
    height: 54px;
  }

  .related-products .product-entity .produse-stock span {
    font-size: 10px;
  }
}

</style>

