<template>
  <div class="pl-0 avantaj-transport-livrare">
    <hr class="top" />
    <span style="padding-right: 3px; display: inline-block">
      <img src="@/assets/images/product/avantaj_transport.png" />
    </span>
    <span class="shipping-text">
      Produs disponibil in stoc din data: {{ date }}
    </span>
    <hr />
  </div>
</template>

<script>
export default {
    name: 'ShippingDate',
    props: ['date']
}
</script>