<template>
  <div class="specification-section">
    <div class="description">
      <div v-if="properties.length" class="specification-title">
        Specificații
      </div>
      <Property
        v-for="(property, index) in properties"
        :property="property"
        :key="index"
      />
      <!-- <div class="description-more">
        <span @click="goto('.product-content')">
          <img width="37" height="37" src="@/assets/images/product/round-plus.svg" />
          Mai multe detalii produs
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Property from "@/components/product_page/Property.vue";

export default {
  name: "Specifications",
  props: ["properties"],
  components: {
    Property,
  },
};
</script>

<style scoped>
.specification-title {
  font-family: "Raleway Bold";
  font-size: 24px;
  color: #000;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .specification-title {
    font-size: 22px;
  }
}
</style>
