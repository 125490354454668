<template>
  <div class="">
    <div class="produs-content">
      <span v-html="description"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["description"],
};
</script>
