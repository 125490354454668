<template>
  <div class="recommended-categories">
    <div class="recommended-title">
      Clienții au cumpărat și
    </div>
    <div id="recommendedCategoriesCarousel" class="recommendedCategoriesCarousel">
      <swiper :breakpoints='breakpoints' :scrollbar='{ draggable: true }' :navigation='navigation' :rewind='true'>
        <swiper-slide v-for="categ in categories" :key="categ.id">
          <div class="rec-category">
            <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">
              <div class="categ-image">
                  <img :src="categ.product_recommendation_image" />
              </div>
              <div class="categ-name">
                {{ categ.name }}
              </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-nav">
        <div class="swiper-button-next-custom"></div>
        <div class="swiper-button-prev-custom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
  components: {
    Swiper, 
    SwiperSlide
  },
  name: "RecommendedCategories",
  data() {
    return {
      breakpoints: {
        100: {
          slidesPerView: 2.2,
          spaceBetween: 8,
          slidesPerGroup: 2,
        },
        576: {
          slidesPerView: 3.5,
          spaceBetween: 10,
          slidesPerGroup: 3,
        },
        768: {
          slidesPerView: 4.5,
          spaceBetween: 15,
          slidesPerGroup: 4,
        },
      },
      navigation: {
        prevEl: '#recommendedCategoriesCarousel .swiper-button-prev-custom',
        nextEl: '#recommendedCategoriesCarousel .swiper-button-next-custom',
      }
    };
  },
  props: {
    categories: {
      type: Array,
    },
  },
};
</script>

<style>
.recommended-categories {
  margin-bottom: 30px;
}

.recommended-categories .recommended-title {
  font-family: 'Raleway Bold';
  font-size: 24px;
  color: #000;
  text-align: left;
  margin-bottom: 15px;
}

#recommendedCategoriesCarousel .rec-category {
  padding-bottom: 50px;
}

#recommendedCategoriesCarousel .swiper-scrollbar {
  left: 0;
  width: 100%;
}

#recommendedCategoriesCarousel .swiper-scrollbar-drag {
  background-color: #000;
}

#recommendedCategoriesCarousel .swiper-button-next-custom, #recommendedCategoriesCarousel .swiper-button-prev-custom {
  position: absolute; 
  background-size: 45px;
  background-position: center;
  width: 45px;
  height: 45px;
  top: 38%;
  z-index: 10;
}

#recommendedCategoriesCarousel .swiper-button-next-custom {
  background-image: url("~@/assets/images/arrow-carousel-right.svg");
  right: -7px;
}

#recommendedCategoriesCarousel .swiper-button-prev-custom {
  background-image: url("~@/assets/images/arrow-carousel-left.svg");  
  left: -7px;
}

.swiper-button-next-custom.swiper-button-disabled, .swiper-button-prev-custom.swiper-button-disabled {
  display: none;
}

#recommendedCategoriesCarousel .categ-name {
  font-family: 'Montserrat SemiBold';
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  padding: 20px 0px 0px;
  line-height: 1;
}

#recommendedCategoriesCarousel .categ-image {
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  width: 100%;
  position: relative;
  padding-top: 100%; 
  border-radius: 9px;
  border: solid 1.5px #e5e5e5; 
}

#recommendedCategoriesCarousel .categ-image img {
  display: block;
  margin: auto;
  position: absolute;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .recommended-categories .recommended-title {
    font-size: 22px;
  }

  #recommendedCategoriesCarousel .categ-name {
    font-size: 12px;
  }

  #recommendedCategoriesCarousel .swiper-nav {
    display: none;
  }
}
</style>

